import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './SurveyTake.css';

const SurveyTake = () => {
  const [survey, setSurvey] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [name, setName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://diplore.ru/api/surveys/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setSurvey(response.data);
        setAnswers(response.data.questions.map(() => ''));
      } catch (error) {
        console.error('Error fetching survey:', error);
        if (error.response && error.response.status === 401) {
          alert('Пожалуйста, войдите в систему для доступа к опросу.');
          navigate('/login');
        }
      }
    };
    fetchSurvey();
  }, [id, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axios.post(`https://diplore.ru/api/surveys/${id}/answer`, { 
        name, 
        birthDate, 
        answers 
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert('Опрос успешно отправлен!');
      navigate('/');
    } catch (error) {
      console.error('Error submitting survey:', error);
      alert('Не удалось отправить опрос. Пожалуйста, попробуйте еще раз.');
    }
  };

  if (!survey) return <div>Загрузка...</div>;

  return (
    <div className="survey-take">
      <h1>{survey.title}</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Полное имя"
          required
        />
        <input
          type="date"
          value={birthDate}
          onChange={(e) => setBirthDate(e.target.value)}
          required
        />
        {survey.questions.map((question, index) => (
          <div key={index} className="question">
            <p>{question.text}</p>
            <input
              type="text"
              value={answers[index]}
              onChange={(e) => {
                const newAnswers = [...answers];
                newAnswers[index] = e.target.value;
                setAnswers(newAnswers);
              }}
              required
            />
          </div>
        ))}
        <button type="submit">Отправить ответы</button>
      </form>
    </div>
  );
};

export default SurveyTake;