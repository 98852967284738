import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SubmitArticlePage.css';

const SubmitArticlePage = () => {
  const [formData, setFormData] = useState({
    title: '',
    abstract: '',
    keywords: '',
    fullText: '',
    file: null
  });
  const [message, setMessage] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: files ? files[0] : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setUploadProgress(0);

    if (formData.file && formData.file.size > 50 * 1024 * 1024) { // 50 MB
      setMessage('Файл слишком большой. Максимальный размер - 50 MB.');
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('title', formData.title);
    formDataToSend.append('abstract', formData.abstract);
    formDataToSend.append('keywords', formData.keywords);
    formDataToSend.append('fullText', formData.fullText);
    if (formData.file) {
      formDataToSend.append('file', formData.file);
    }

    try {
      const response = await axios.post('https://diplore.ru/api/articles', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });
      setMessage('Статья успешно отправлена!');
      // Очистка формы после успешной отправки
      setFormData({
        title: '',
        abstract: '',
        keywords: '',
        fullText: '',
        file: null
      });
      // Очистка input file
      document.getElementById('file').value = '';
      setUploadProgress(0);
    } catch (error) {
      console.error('Ошибка при отправке статьи:', error);
      setMessage('Произошла ошибка при отправке статьи. Пожалуйста, попробуйте еще раз.');
      setUploadProgress(0);
    }
  };

  useEffect(() => {
    const animateElements = () => {
      const elements = document.querySelectorAll('.animate-in');
      elements.forEach((el, index) => {
        setTimeout(() => {
          el.style.opacity = '1';
          el.style.transform = 'translateY(0)';
        }, 200 * index);
      });
    };

    animateElements();
  }, []);

  return (
    <div className="submit-article-page">
      <h1 className="page-title animate-in">Опубликовать статью</h1>
      <p className="page-subtitle animate-in">
        Поделитесь своим исследованием с научным сообществом через Diplore Journal
      </p>
      
      <div className="content-section">
        <div className="grid-layout">
          <div className="card animate-in">
            <h2>Правила подачи статьи</h2>
            <ul>
              <li>Статья должна соответствовать тематике журнала</li>
              <li>Объем статьи - от 5000 до 8000 слов</li>
              <li>Статья должна быть оригинальной и не опубликованной ранее</li>
              <li>Используйте стандартный научный стиль оформления</li>
              <li>Максимальный размер прикрепляемого файла - 50 MB</li>
            </ul>
          </div>
          
          <div className="card animate-in">
            <h2>Форма подачи статьи</h2>
            {message && <div className={message.includes('успешно') ? 'success-message' : 'error-message'}>{message}</div>}
            {uploadProgress > 0 && uploadProgress < 100 && (
              <div className="upload-progress">
                Загрузка: {uploadProgress}%
                <div className="progress-bar" style={{width: `${uploadProgress}%`}}></div>
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="title">Название статьи</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="abstract">Аннотация</label>
                <textarea
                  id="abstract"
                  name="abstract"
                  value={formData.abstract}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              
              <div className="form-group">
                <label htmlFor="keywords">Ключевые слова</label>
                <input
                  type="text"
                  id="keywords"
                  name="keywords"
                  value={formData.keywords}
                  onChange={handleChange}
                  required
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="fullText">Полный текст статьи</label>
                <textarea
                  id="fullText"
                  name="fullText"
                  value={formData.fullText}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              
              <div className="form-group">
                <label htmlFor="file">Прикрепить файл (PDF, макс. 50 MB)</label>
                <input
                  type="file"
                  id="file"
                  name="file"
                  onChange={handleChange}
                  accept=".pdf"
                />
              </div>
              
              <button type="submit" className="btn">Отправить статью</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmitArticlePage;