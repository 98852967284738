
// frontend/src/pages/tools/TutorPlus.js
import React from 'react';

const TutorPlus = () => {
  return (
    <div className="tutor-plus">
      <h1>Тьютор+</h1>
      {/* Здесь будет реализация Тьютор+ */}
    </div>
  );
};

export default TutorPlus;