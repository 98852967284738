// src/pages/journal/EditorialBoardPage.js
import React from 'react';
import './EditorialBoardPage.css';

const EditorialBoardPage = () => {
  return (
    <div className="editorial-board-page">
      <h1>Редакционная коллегия</h1>
      <div className="board-members">
        {/* Здесь будет список членов редакционной коллегии */}
      </div>
    </div>
  );
};

export default EditorialBoardPage;