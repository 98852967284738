import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import BannerSlider from '../components/BannerSlider/BannerSlider';
import VerticalPostersSlider from '../components/VerticalPostersSlider/VerticalPostersSlider';
import '../styles/HomePage.css';

const HomePage = () => {
  useEffect(() => {
    const words = document.querySelectorAll('.animate-word');
    const subtitle = document.querySelector('.hero-subtitle');

    // Анимация заголовков
    words.forEach((word, index) => {
      setTimeout(() => {
        word.style.opacity = '1';
        word.style.transform = 'translateY(0)';
      }, 400 * index);
    });

    // Анимация подзаголовка
    setTimeout(() => {
      subtitle.style.opacity = '1';
      subtitle.style.transform = 'translateY(0)';
    }, 300 * words.length);
    
    // Анимация блоков
    const blocks = document.querySelectorAll('.animated-block');
    blocks.forEach((block, index) => {
      setTimeout(() => {
        block.style.opacity = '1';
        block.style.transform = 'translateY(0)';
      }, 600 * index);
    });
  }, []);

  return (
    <div className="home-page">
      <section className="hero full-screen">
        <div className="hero-content">
          <h1>
            <span className="animate-word">Меньше</span>{' '}
            <span className="animate-word">поисков</span>{''}
            <span className="animate-word">,</span>{' '}
            <span className="animate-word">больше</span>{' '}
            <span className="animate-word">открытий</span>
          </h1>
          <p className="hero-subtitle">
            Всё, что нужно для научной работы: поиск литературы, 
            анализ и удобные инструменты для студентов и исследователей.
          </p>
        </div>
      </section>

      <section className="banner-section">
        <BannerSlider />
      </section>

      <section className="posters-section">
        <h2>Исследования и разработки</h2>
        <VerticalPostersSlider />
      </section>

      <section className="posters-section">
        <h2>Исследования и разработки</h2>
        <VerticalPostersSlider />
      </section>

      <section className="cta">
        <h2>Instant answers. Greater productivity. Endless inspiration.</h2>
        <Link to="/tools" className="cta-button">Воспользуйтесь инструментами Diplore</Link>
      </section>
    </div>
  );
};


export default HomePage;