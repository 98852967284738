import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import SubHeader from './components/SubHeader/SubHeader.js';
import HomePage from './pages/HomePage';
import ResearchPage from './pages/ResearchPage';
import ProductsPage from './pages/ProductsPage';
import SafetyPage from './pages/SafetyPage';
import CompanyPage from './pages/CompanyPage';
import ToolsPage from './pages/ToolsPage';
import QuoteGenerator from './pages/tools/QuoteGenerator';
import SourceGenerator from './pages/tools/SourceGenerator';
import TutorPlus from './pages/tools/TutorPlus';
import CreateSurvey from './pages/tools/CreateSurvey';
import SurveyTake from './pages/tools/surveys/SurveyTake';
import SurveyResults from './pages/tools/surveys/SurveyResults';
import JournalPage from './pages/journal/JournalPage';
import SubmitArticlePage from './pages/journal/SubmitArticlePage';
import ArchivePage from './pages/journal/ArchivePage';
import EditorialBoardPage from './pages/journal/EditorialBoardPage';
import Register from './components/Auth/Register';
import Login from './components/Auth/Login';
import Profile from './components/Profile'; // Импортируем компонент Profile
import './styles/Global.css';

function App() {
  return (
    <Router>
      <div className="app">
        <Header />
        <Routes>
          <Route path="/tools/*" element={<ToolsLayout />} />
          <Route path="/journal/*" element={<JournalLayout />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/research" element={<ResearchPage />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/safety" element={<SafetyPage />} />
          <Route path="/company" element={<CompanyPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/survey/:id" element={<SurveyTake />} />
          <Route path="/profile/:userId" element={<Profile />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

function ToolsLayout() {
  return (
    <>
      <main>
        <Routes>
          <Route index element={<ToolsPage />} />
          <Route path="quote-generator" element={<QuoteGenerator />} />
          <Route path="source-generator" element={<SourceGenerator />} />
          <Route path="tutor-plus" element={<TutorPlus />} />
          <Route path="create-survey" element={<CreateSurvey />} />
          <Route path="surveys/:id/results" element={<SurveyResults />} />
        </Routes>
      </main>
    </>
  );
}

function JournalLayout() {
  return (
    <>
      <main>
        <Routes>
          <Route index element={<JournalPage />} />
          <Route path="submit" element={<SubmitArticlePage />} />
          <Route path="archive" element={<ArchivePage />} />
          <Route path="editorial-board" element={<EditorialBoardPage />} />
        </Routes>
      </main>
    </>
  );
}

export default App;