// SafetyPage.js
import React from 'react';

const SafetyPage = () => {
  return (
    <div className="safety-page">
      <h1>Safety</h1>
      {/* Add safety content here */}
    </div>
  );
};

export default SafetyPage;