// CompanyPage.js
import React from 'react';

const CompanyPage = () => {
  return (
    <div className="company-page">
      <h1>Company</h1>
      {/* Add company content here */}
    </div>
  );
};

export default CompanyPage;