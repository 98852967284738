// ResearchPage.js
import React from 'react';

const ResearchPage = () => {
  return (
    <div className="research-page">
      <h1>Research</h1>
      {/* Add research content here */}
    </div>
  );
};

export default ResearchPage;
