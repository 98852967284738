import React, { useEffect, useRef } from 'react';
import './JournalPage.css';

const JournalPage = () => {
  const heroRef = useRef(null);
  const subtitleRef = useRef(null);
  const featuresRef = useRef(null);

  useEffect(() => {
    const heroElement = heroRef.current;
    const subtitleElement = subtitleRef.current;
    const featuresElement = featuresRef.current;

    if (heroElement && subtitleElement && featuresElement) {
      const words = heroElement.innerText.split(' ');
      heroElement.innerHTML = words.map(word => `<span class="animate-word">${word}</span>`).join(' ');

      const animateWords = () => {
        const animatedWords = heroElement.querySelectorAll('.animate-word');
        animatedWords.forEach((word, index) => {
          setTimeout(() => {
            word.style.opacity = '1';
            word.style.transform = 'translateY(0)';
          }, index * 100);
        });
      };

      const animateSubtitle = () => {
        subtitleElement.style.opacity = '1';
        subtitleElement.style.transform = 'translateY(0)';
      };

      const animateFeatures = () => {
        const features = featuresElement.querySelectorAll('.animated-block');
        features.forEach((feature, index) => {
          setTimeout(() => {
            feature.style.opacity = '1';
            feature.style.transform = 'translateY(0)';
          }, index * 200);
        });
      };

      animateWords();
      setTimeout(animateSubtitle, words.length * 100);
      setTimeout(animateFeatures, (words.length * 100) + 500);
    }
  }, []);

  return (
    <div className="journal-page">
      <section className="full-screen">
        <div className="hero">
          <div className="hero-content">
            <h1 ref={heroRef}>Научный журнал Diplore</h1>
            <p ref={subtitleRef} className="hero-subtitle">
              Объединяем ученых, продвигаем науку, создаем будущее
            </p>
          </div>
        </div>
      </section>

      <section className="key-features">
        <h2>Наша миссия</h2>
        <p className="section-subtitle">
          Diplore стремится поддерживать и продвигать высококачественные научные исследования, 
          способствуя прогрессу в различных областях науки.
        </p>
        <div ref={featuresRef} className="features-grid">
          <div className="animated-block">
            <h3>Объединение ученых</h3>
            <p>Создаем платформу для сотрудничества и обмена идеями между исследователями разных дисциплин.</p>
          </div>
          <div className="animated-block">
            <h3>Качество превыше всего</h3>
            <p>Гарантируем высокий уровень публикаций через строгий процесс рецензирования и отбора работ.</p>
          </div>
          <div className="animated-block">
            <h3>Поддержка инноваций</h3>
            <p>Способствуем развитию науки, поддерживая инновационные исследования и междисциплинарные проекты.</p>
          </div>
        </div>
      </section>

      <section className="cta">
        <h2>Станьте частью научного сообщества Diplore</h2>
        <p>Присоединяйтесь к нам, чтобы внести свой вклад в развитие науки и получить поддержку для ваших исследований.</p>
        <a href="#" className="cta-button">Подать заявку</a>
      </section>
    </div>
  );
};

export default JournalPage;