import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SurveyResults.css';

const SurveyResults = () => {
  const [surveys, setSurveys] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [results, setResults] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    fetchSurveys();
  }, []);

  const fetchSurveys = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://diplore.ru/api/surveys', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSurveys(response.data);
    } catch (error) {
      console.error('Failed to fetch surveys:', error);
    }
  };

  const fetchResults = async (surveyId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://diplore.ru/api/surveys/${surveyId}/results`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setResults(response.data);
      if (response.data.length > 0) {
        setSelectedQuestion(response.data[0].questionId);
      }
    } catch (error) {
      console.error('Failed to fetch survey results:', error);
      setResults([]);
    }
  };

  const handleSurveySelect = (e) => {
    const surveyId = e.target.value;
    setSelectedSurvey(surveyId);
    if (surveyId) {
      fetchResults(surveyId);
    } else {
      setResults([]);
      setSelectedQuestion(null);
    }
  };

  const handleQuestionSelect = (questionId) => {
    setSelectedQuestion(questionId);
    setSortOrder('asc');
  };

  const sortAnswers = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    const currentQuestion = results.find(q => q.questionId === selectedQuestion);
    if (currentQuestion) {
      const sortedAnswers = [...currentQuestion.answers].sort((a, b) => {
        return newSortOrder === 'asc' 
          ? a.answerText.localeCompare(b.answerText) 
          : b.answerText.localeCompare(a.answerText);
      });
      setResults(results.map(q => 
        q.questionId === selectedQuestion 
          ? {...q, answers: sortedAnswers} 
          : q
      ));
    }
  };

  return (
    <div className="survey-results-container">
      <h1>Survey Results</h1>
      <select onChange={handleSurveySelect} value={selectedSurvey || ''}>
        <option value="">Select a survey</option>
        {surveys.map((survey) => (
          <option key={survey.id} value={survey.id}>{survey.title}</option>
        ))}
      </select>

      {results.length > 0 && (
        <div className="survey-results">
          <div className="questions-list">
            <h2>Questions</h2>
            {results.map((question) => (
              <button
                key={question.questionId}
                className={`question-button ${selectedQuestion === question.questionId ? 'active' : ''}`}
                onClick={() => handleQuestionSelect(question.questionId)}
              >
                {question.questionText}
              </button>
            ))}
          </div>
          {selectedQuestion && (
            <div className="answers-table">
              <h2>{results.find(q => q.questionId === selectedQuestion)?.questionText}</h2>
              <table>
                <thead>
                  <tr>
                    <th onClick={sortAnswers} className="sortable">
                      Answer {sortOrder === 'asc' ? '▲' : '▼'}
                    </th>
                    <th>Name</th>
                    <th>Birth Date</th>
                  </tr>
                </thead>
                <tbody>
                  {results.find(q => q.questionId === selectedQuestion)?.answers.map((answer, index) => (
                    <tr key={index}>
                      <td>{answer.answerText}</td>
                      <td>{answer.name}</td>
                      <td>{new Date(answer.birthDate).toLocaleDateString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SurveyResults;