import React from 'react';

const QuoteGenerator = () => {
  return (
    <div className="quote-generator">
      <h1>Генератор цитат</h1>
      {/* Здесь будет реализация генератора цитат */}
    </div>
  );
};

export default QuoteGenerator;
