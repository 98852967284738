// src/pages/journal/ArchivePage.js
import React from 'react';
import './ArchivePage.css';

const ArchivePage = () => {
  return (
    <div className="archive-page">
      <h1>Архив выпусков</h1>
      <div className="archive-list">
        {/* Здесь будет список архивных выпусков */}
      </div>
    </div>
  );
};

export default ArchivePage;