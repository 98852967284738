import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import './VerticalPostersSlider.css';

// Импортируем изображения
import poster1 from './images/poster1.jpg';
import poster2 from './images/poster2.jpg';
import poster3 from './images/poster3.jpg';
import poster4 from './images/poster4.jpg';
import poster5 from './images/poster5.jpg';
import poster6 from './images/poster6.jpg';
import poster7 from './images/poster7.jpg';

const posters = [
  { title: "Research", subtitle: "Learning to Reason with LLMs", link: "/llm-reasoning", image: poster1 },
  { title: "For Everyone", subtitle: "o1 System Card", link: "/o1-system", image: poster2 },
  { title: "Writing with AI", subtitle: "GPT-4o System Card", link: "/gpt4o-system", image: poster3 },
  { title: "For Business", subtitle: "GPT-4 API General Availability", link: "/gpt4-api", image: poster4 },
  { title: "ChatGPT Enterprise", subtitle: "Advanced AI solutions", link: "/chatgpt-enterprise", image: poster5 },
  { title: "ChatGPT Team", subtitle: "Collaborate with AI", link: "/chatgpt-team", image: poster6 },
  { title: "API Platform", subtitle: "Build with OpenAI", link: "/api-platform", image: poster7 }
];

const VerticalPostersSlider = () => {
  const sliderRef = useRef(null);

  const scroll = (direction) => {
    if (sliderRef.current) {
      const { scrollLeft, clientWidth } = sliderRef.current;
      const scrollTo = direction === 'left' ? scrollLeft - clientWidth : scrollLeft + clientWidth;
      sliderRef.current.scrollTo({ left: scrollTo, behavior: 'smooth' });
    }
  };

  return (
    <div className="vertical-posters-container">
      <h3 className="section-title">ChatGPT</h3>
      <div className="vertical-posters-slider" ref={sliderRef}>
        {posters.map((poster, index) => (
          <Link to={poster.link} key={index} className="poster" style={{ backgroundImage: `url(${poster.image})` }}>
            <div className="poster-content">
              <h4>{poster.title}</h4>
              <p>{poster.subtitle}</p>
            </div>
          </Link>
        ))}
      </div>
      <div className="slider-controls">
        <button onClick={() => scroll('left')} className="slider-arrow left">&#8249;</button>
        <button onClick={() => scroll('right')} className="slider-arrow right">&#8250;</button>
      </div>
    </div>
  );
};

export default VerticalPostersSlider;