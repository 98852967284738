import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './BannerSlider.css';

const banners = [
  {
    title: "OpenAI o1",
    subtitle: "A new series of AI models designed to spend more time thinking before they respond.",
    buttonText: "Learn more",
    link: "/openai-o1",
    color: "#FFD700"
  },
  {
    title: "ChatGPT",
    subtitle: "Our flagship conversational AI, now with even more capabilities.",
    buttonText: "Try now",
    link: "/chatgpt",
    color: "#4CAF50"
  },
  {
    title: "DALL-E 3",
    subtitle: "Create stunning images from text descriptions.",
    buttonText: "Generate art",
    link: "/dall-e",
    color: "#2196F3"
  }
];

const BannerSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % banners.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + banners.length) % banners.length);
  };

  return (
    <div className="banner-slider-container">
      <div className="banner-slider" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
        {banners.map((banner, index) => (
          <div key={index} className="banner" style={{ backgroundColor: banner.color }}>
            <div className="banner-content">
              <h2>{banner.title}</h2>
              <p>{banner.subtitle}</p>
              <Link to={banner.link} className="banner-button">{banner.buttonText}</Link>
            </div>
          </div>
        ))}
      </div>
      <div className="slider-controls">
        <button onClick={prevSlide} className="slider-arrow left">&#8249;</button>
        <button onClick={nextSlide} className="slider-arrow right">&#8250;</button>
      </div>
      <div className="slider-dots">
        {banners.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentSlide ? 'active' : ''}`}
            onClick={() => setCurrentSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default BannerSlider;