// frontend/src/pages/ToolsPage.js
import React from 'react';
import SubHeader from '../components/SubHeader/SubHeader';
import '../styles/ToolsPage.css';

const ToolsPage = () => {
  return (

      <div className="content">
        <h1>Инструменты Diplore</h1>
        <p>Добро пожаловать на страницу инструментов Diplore! Здесь вы найдете полезные инструменты для улучшения вашего образовательного опыта.</p>
        <div className="tools-grid">
          <div className="tool-card">
            <h2>Генератор цитат</h2>
            <p>Создавайте вдохновляющие и релевантные цитаты для ваших работ.</p>
          </div>
          <div className="tool-card">
            <h2>Генератор источников</h2>
            <p>Автоматически генерируйте список источников в нужном формате.</p>
          </div>
          <div className="tool-card">
            <h2>Тьютор+</h2>
            <p>Получите персонализированную помощь в обучении от нашего ИИ-тьютора.</p>
          </div>
        </div>
      </div>

  );
};

export default ToolsPage;
