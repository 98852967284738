import React, { useState, useEffect } from 'react';
import './SourceGenerator.css';

const categories = {
  'Документы': ['Конституция РФ', 'Федеральный закон', 'ГОСТ'],
  'Электронный ресурс': ['Сайт', 'Статья из научного сборника', 'Статья из журнала', 'Статья из газеты', 'Статья из электронного журнала', 'Статья из электронной газеты', 'Статья с сайта'],
  'Книги': ['Книга', 'Книга без автора', 'Книга из ЭБС', 'Многотомное издание', 'Отдельный том многотомного издания'],
  'Диссертации': []
};

const SourceGenerator = () => {
  const [sources, setSources] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const savedSources = localStorage.getItem('sources');
    if (savedSources) {
      setSources(JSON.parse(savedSources));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('sources', JSON.stringify(sources));
  }, [sources]);

  const getFormFields = () => {
    switch (selectedSubcategory) {
      case 'Статья с сайта':
        return ['authorName', 'articleTitle', 'websiteName', 'websiteUrl'];
      case 'Книга':
        return ['authorLastName', 'authorInitials', 'bookTitle', 'publicationPlace', 'publisher', 'publicationYear', 'pageCount'];
      default:
        return [];
    }
  };

  const getFieldLabel = (field) => {
    const labels = {
      authorName: 'ФИО Автора',
      articleTitle: 'Название статьи на сайте',
      websiteName: 'Название сайта',
      websiteUrl: 'Ссылка на сайт',
      authorLastName: 'Фамилия автора',
      authorInitials: 'Инициалы автора',
      bookTitle: 'Название книги',
      publicationPlace: 'Место издания',
      publisher: 'Издательство',
      publicationYear: 'Год издания книги',
      pageCount: 'Количество страниц'
    };
    return labels[field] || field;
  };

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const addSource = () => {
    let newSource = '';
    const currentDate = new Date().toLocaleDateString('ru-RU');

    if (selectedSubcategory === 'Статья с сайта') {
      newSource = `${formData.authorName}. ${formData.articleTitle} // ${formData.websiteName} : сайт. - URL: ${formData.websiteUrl} (дата обращения: ${currentDate})`;
    } else if (selectedSubcategory === 'Книга') {
      newSource = `${formData.authorLastName}, ${formData.authorInitials}. ${formData.bookTitle} / ${formData.authorInitials} ${formData.authorLastName}. - ${formData.publicationPlace} : ${formData.publisher}, ${formData.publicationYear}. - ${formData.pageCount} с.`;
    }

    setSources([...sources, newSource]);
    setFormData({});
  };

  const copyToClipboard = () => {
    const text = sources.map((source, index) => `${index + 1}. ${source}`).join('\n');
    navigator.clipboard.writeText(text).then(() => {
      alert('Список скопирован в буфер обмена');
    }, (err) => {
      console.error('Не удалось скопировать текст: ', err);
    });
  };

  const clearList = () => {
    setSources([]);
    localStorage.removeItem('sources');
  };

  const sortAlphabetically = () => {
    const sorted = [...sources].sort((a, b) => a.localeCompare(b, 'ru'));
    setSources(sorted);
  };

  return (
    <div className="source-generator">
      <h1>Список литературы</h1>
      <div className="sources-list">
        {sources.length === 0 ? (
          <p className="empty-list">Тут появится список источников после добавления...</p>
        ) : (
          sources.map((source, index) => (
            <div key={index} className="source-item">
              {index + 1}. {source}
            </div>
          ))
        )}
      </div>
      <div className="button-group">
        <button onClick={copyToClipboard}>Скопировать</button>
        <button onClick={clearList}>Очистить</button>
        <button onClick={sortAlphabetically}>Сортировать по алфавиту</button>
      </div>
      <div className="generator-interface">
        <div className="category-selection">
          {Object.keys(categories).map(category => (
            <div key={category} className="category">
              <button
                className={`category-button ${selectedCategory === category ? 'active' : ''}`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </button>
              {selectedCategory === category && (
                <div className="subcategory-list">
                  {categories[category].map(subcategory => (
                    <button
                      key={subcategory}
                      className={`subcategory-button ${selectedSubcategory === subcategory ? 'active' : ''}`}
                      onClick={() => setSelectedSubcategory(subcategory)}
                    >
                      {subcategory}
                    </button>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        {selectedSubcategory && (
          <div className="source-form">
            {getFormFields().map(field => (
              <input
                key={field}
                type="text"
                placeholder={getFieldLabel(field)}
                value={formData[field] || ''}
                onChange={(e) => handleInputChange(field, e.target.value)}
              />
            ))}
            <button onClick={addSource}>Добавить источник</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SourceGenerator;