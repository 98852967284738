import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CreateSurvey.css';

const CreateSurvey = () => {
  const [title, setTitle] = useState('');
  const [questions, setQuestions] = useState([{ text: '' }]);
  const [surveyLink, setSurveyLink] = useState('');
  const [surveys, setSurveys] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [results, setResults] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  const handleAddQuestion = () => {
    setQuestions([...questions, { text: '' }]);
  };

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].text = value;
    setQuestions(newQuestions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('https://diplore.ru/api/surveys', { title, questions }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSurveyLink(`https://diplore.ru/survey/${response.data.id}`);
      alert('Survey created successfully!');
      fetchSurveys();
    } catch (error) {
      alert('Failed to create survey. Please try again.');
    }
  };

  const fetchSurveys = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://diplore.ru/api/surveys', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSurveys(response.data);
    } catch (error) {
      console.error('Failed to fetch surveys:', error);
    }
  };

  const fetchResults = async (surveyId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://diplore.ru/api/surveys/${surveyId}/results`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setResults(response.data);
      if (response.data.length > 0) {
        setSelectedQuestion(response.data[0].questionId);
      }
    } catch (error) {
      console.error('Failed to fetch survey results:', error);
      setResults([]);
    }
  };

  const handleQuestionSelect = (questionId) => {
    setSelectedQuestion(questionId);
    setSortOrder('asc');
  };

  const sortAnswers = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    const currentQuestion = results.find(q => q.questionId === selectedQuestion);
    if (currentQuestion) {
      const sortedAnswers = [...currentQuestion.answers].sort((a, b) => {
        return newSortOrder === 'asc' 
          ? a.answerText.localeCompare(b.answerText) 
          : b.answerText.localeCompare(a.answerText);
      });
      setResults(results.map(q => 
        q.questionId === selectedQuestion 
          ? {...q, answers: sortedAnswers} 
          : q
      ));
    }
  };

  useEffect(() => {
    fetchSurveys();
  }, []);

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <h1>Create a New Survey</h1>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Survey Title"
          required
        />
        {questions.map((question, index) => (
          <input
            key={index}
            type="text"
            value={question.text}
            onChange={(e) => handleQuestionChange(index, e.target.value)}
            placeholder={`Question ${index + 1}`}
            required
          />
        ))}
        <button type="button" onClick={handleAddQuestion}>Add Question</button>
        <button type="submit">Create Survey</button>
      </form>

      {surveyLink && (
        <div className="survey-link">
          <p>Share this link with your friends:</p>
          <a href={surveyLink} target="_blank" rel="noopener noreferrer">{surveyLink}</a>
        </div>
      )}

      <div className="surveys-list">
        <h2>Your Surveys</h2>
        <select onChange={(e) => {
          setSelectedSurvey(e.target.value);
          fetchResults(e.target.value);
        }}>
          <option value="">Select a survey</option>
          {surveys.map((survey) => (
            <option key={survey.id} value={survey.id}>{survey.title}</option>
          ))}
        </select>
      </div>

      {results.length > 0 && (
        <div className="results-container">
          <h2>Survey Results</h2>
          <div className="questions-list">
            {results.map((question) => (
              <button
                key={question.questionId}
                className={`question-button ${selectedQuestion === question.questionId ? 'active' : ''}`}
                onClick={() => handleQuestionSelect(question.questionId)}
              >
                {question.questionText}
              </button>
            ))}
          </div>
          {selectedQuestion && (
            <table className="results-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Birth Date</th>
                  <th onClick={sortAnswers} className="sortable">
                    Answer {sortOrder === 'asc' ? '▲' : '▼'}
                  </th>
                </tr>
              </thead>
              <tbody>
                {results.find(q => q.questionId === selectedQuestion)?.answers.map((answer, index) => (
                  <tr key={index}>
                    <td>{answer.name}</td>
                    <td>{new Date(answer.birthDate).toLocaleDateString()}</td>
                    <td>{answer.answerText}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default CreateSurvey; 